export default [
  {
    path: '/topics',
    name: 'topics',
    component: () => import('@/views/modules/topics/Topics.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Topics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-topic',
    name: 'add_topic',
    component: () => import('@/views/modules/topics/EditTopic.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Topics',
          to: '/topics'
        },
        {
          text: 'Add Topic',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-topic/:id',
    name: 'edit_topic',
    component: () => import('@/views/modules/topics/EditTopic.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Topics',
          to: '/topics'
        },
        {
          text: 'edit_bread',
          active: true,
        },
      ],
    },
  },
  {
    path: '/show-topic/:id',
    name: 'show_topic',
    component: () => import('@/views/modules/topics/ShowTopic.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Topics',
          to: '/topics'
        },
        {
          text: 'show_bread',
          active: true,
        },
      ],
    },
  },
]
