import {login, getMSLoginUrl, msLoginCallback, logout, Register, emailVerificationCode, verifyCode, forgotPassword, resetPassword} from '@/api/system/auth'; // getInfo
import User from '../../../../api/user_settings/users/user';
import { Auth, removeAuth, deleteToken, removeToken, resetPermission, setLogged, setToken } from '../../../../utils/auth';
import fileDownload from "js-file-download";
import router from "@/router";

const user = new User();

const userModule = {
  namespaced: true,
  state: {
    auth: null,
    item: null,
    user: null,
    items: [],
    lookups: [],
    total: 0,
    load: false,
    userAccess:[],
    photoData:null,
    avatar: null,
    roles:[],
    role:{},
    permissions:[],
    user_permissions:[],
    schools:[],
    types:[],
    authUserPermission: [],
    assigned_permissions:[]
  },
  getters: {
    items(state) {
      return state.items;
    },
    roles(state) {
      return state.roles;
    },
    role(state) {
      return state.role;
    },
    item(state) {
      return state.item;
    },
    load(state) {
      return state.load;
    },
    total(state) {
      return state.total;
    },
    status(state) {
      return state.item.removed;
    },
    lookups(state) {
      return state.lookups;
    },
    userAccess(state){
      return state.userAccess;
    },
    user(state){
      return state.user;
    },
    permissions(state){
      return state.permissions;
    },
    user_permissions(state){
        return state.user_permissions;
      },
    schools(state){
      return state.schools;
    },
    types(state){
      return state.types;
    },
    assigned_permissions(state){
      return state.assigned_permissions;
    },
    authUserPermissionsName(state) {
      return Object.entries(state.authUserPermission).map(([key, value]) => {
          return value;
      });
    },
  },
  mutations: {
    SET_AUTH: (state, user) => {
      state.auth = user;
    },
    SET_USERS: (state, users) => {
      state.items = users;
    },
    SET_TOTAL_USERS: (state, total) => {
      state.total = total;
    },
    SET_USERS_LOAD: (state, load) => {
      state.load = load;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
    SET_USER_ACCESS(state,userAccess){
      state.userAccess=userAccess;
    },
    SET_PHOTO(state,data){
      state.photoData = data
    },
    SET_ROLES(state,data){
      state.roles = data
    },
    SET_ROLE(state,data){
      state.role = data
    },
    SET_PERMISSIONS(state,data){
      state.permissions = data
    },
    SET_USER_PERMISSIONS(state,data){
        state.user_permissions = data
      },
    SET_SCHOOLS(state,data){
      state.schools = data
    },
    SET_TYPES(state,data){
      state.types = data
    },
    SET_ASSIGNED_PERMISSIONS(state,data){
      state.assigned_permissions = data
    },
    SET_AUTH_USER_PERMISSIONS(state, permissions) {
      state.authUserPermission = permissions;
    },
  },
  actions: {
    updateBreadVale({ commit }, val) {
        commit('global/UPDATE_BREAD_VAL', val, { root: true })
    },
    register({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        Register(payload.query).then(response => {
          if(response.user.school_status){
            commit('SET_USER', response.user)
            setLogged('1');
            setToken(response.access_token);
            Auth(response);
          }

          resolve(response);
          commit('SET_USERS_LOAD', false);
        }).catch(error => {
          commit('app/UPDATE_LOAD', {link:'login',data:false},{root:true});
          reject(error);
        });
      });
    },
    emailVerificationCode({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        emailVerificationCode(payload.query).then(response => {
          resolve(response);
          commit('SET_USERS_LOAD', false);
        }).catch(error => {
          commit('app/UPDATE_LOAD', {link:'login',data:false},{root:true});
          reject(error);
        });
      });
    },
    verifyCode({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        verifyCode(payload.query).then(response => {
          resolve(response);
          commit('SET_USERS_LOAD', false);
        }).catch(error => {
          commit('app/UPDATE_LOAD', {link:'login',data:false},{root:true});
          reject(error);
        });
      });
    },
    avatar({ commit }, payload) {
      commit('app/UPDATE_LOAD', true, { root: true });
      return new Promise((resolve, reject) => {
        user
          .avatar(payload)
          .then(response => {
            commit('app/UPDATE_LOAD', false, { root: true });
            resolve(response);
          })
          .catch(error => {
            commit('app/UPDATE_LOAD', false, { root: true });
            reject(error);
          });
      });
    },
    login({ commit, dispatch }, payload) {
      commit('app/UPDATE_LOAD', {link:'login',data:true},{root:true});
      return new Promise((resolve, reject) => {
        login(payload.query)
          .then(response => {
            if(response.user.school_status){
              setLogged('1');
              setToken(response.access_token);
              Auth(response);
              commit('SET_USERS_LOAD', true);
            }
            resolve(response);
          })
          .catch(error => {
            commit('app/UPDATE_LOAD', {link:'login',data:false},{root:true});
            if (error.response.status == 503) {
              router.push({name: 'misc-under-maintenance'})
            }
            reject(error);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            setLogged('0');
            removeToken();
            deleteToken();
            removeAuth();
            resetPermission();
            resolve();
          })
          .catch(error => {
            setLogged('0');
            removeToken();
            deleteToken();
            removeAuth();
            resetPermission();
            reject(error);
          });
      });
    },
    forgotPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        forgotPassword(payload.query).then(response => {
          resolve(response);
          commit('SET_USERS_LOAD', false);
        }).catch(error => {
          commit('app/UPDATE_LOAD', {link:'login',data:false},{root:true});
          reject(error);
        });
      });
    },
    resetPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        resetPassword(payload.query).then(response => {
          resolve(response);
          commit('SET_USERS_LOAD', false);
        }).catch(error => {
          commit('app/UPDATE_LOAD', {link:'login',data:false},{root:true});
          reject(error);
        });
      });
    },
    users({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        user.list(payload.query).then(response => {
          commit('SET_USERS', response.users);
          // commit('SET_USERS', response.users.data);
          commit('SET_SCHOOLS', response.schools);
          commit('SET_USERS_LOAD', false);
          resolve();
        });
      });
    },
    showUser({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        user.showUser(payload).then(response => {
          commit('SET_USER', response.user);
          commit('SET_USERS_LOAD', false);
          dispatch('schools/updateBreadVale', response.user.fname, { root: true })
          resolve();
        });
      });
    },
    assignRole({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        user.assignRole(payload.id, payload.query).then(response => {
          commit('SET_USERS_LOAD', false);
          
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });;
      });
    },
    inactiveUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        user
          .inactiveUser(id)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    schoolLookups({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        user.schoolLookups().then(response => {
          commit('SET_SCHOOLS', response.schools);
          commit('SET_TYPES', response.roles);
          commit('SET_USERS_LOAD', false);
          resolve();
        });
      });
    },
    listRoles({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        user.listRoles().then(response => {
          commit('SET_ROLES', response.roles);
          commit('SET_USERS_LOAD', false);
          resolve();
        });
      });
    },
    listPermissions({ commit,dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true); 
        user.listPermissions(payload).then(response => {
          commit('SET_PERMISSIONS', response.permissions_groups);
          commit('SET_ASSIGNED_PERMISSIONS', response.permissions);
          commit('SET_ROLE', response.role);
          dispatch('schools/updateBreadVale', response.role.name, { root: true })
          commit('SET_USERS_LOAD', false);
          resolve();
        });
      });
    },
    userPermissions({ commit }, payload) {
        return new Promise((resolve, reject) => {
          commit('SET_USERS_LOAD', true);
          user.userPermissions(payload).then(response => {
            commit('SET_USER_PERMISSIONS', response.role_permissions);
            commit('SET_USERS_LOAD', false);
            resolve();
          });
        });
      },
    addRole({ commit }, payload) {
      commit('app/UPDATE_LOAD', true, { root: true });
      return new Promise((resolve, reject) => {
        user
          .addRole(payload.id ,payload.query )
          .then(response => {
            commit('app/UPDATE_LOAD', false, { root: true });
            resolve(response);
          })
          .catch(error => {
            commit('app/UPDATE_LOAD', false, { root: true });
            reject(error);
          });
      });
    },
    put({ commit }, payload) {
      commit('app/UPDATE_LOAD', true, { root: true });
      return new Promise((resolve, reject) => {
        user
          .put(payload.id, payload.query)
          .then(response => {
            commit('app/UPDATE_LOAD', false, { root: true });
            resolve(response);
          })
          .catch(error => {
            commit('app/UPDATE_LOAD', false, { root: true });
            reject(error);
          });
      });
    },
    setAccessControl({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        user
          .setAccessControl(payload)
          .then(response => {
            commit('SET_USERS_LOAD', false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    uploadPhoto({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        user
          .setPhoto(payload)
          .then(response => {
            commit('SET_PHOTO', response.data);
            commit('SET_USERS_LOAD', false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    get({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        user.getData(id)
          .then(response => {
            commit('SET_USER', response.data);
            if ( response && response.data && typeof response.data == 'object') {
              commit('app/UPDATE_PAGE_DETAILS', response.data, {root: true});
            }
            commit('SET_USERS_LOAD', false);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    remove({ commit }, id) {
      return new Promise((resolve, reject) => {
        user
          .remove(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    restore({ commit }, id) {
      return new Promise((resolve, reject) => {
        user
          .restore(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    accessUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        user
          .addAccessUser(payload)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getUserAccess({commit},id) {
      commit('SET_USERS_LOAD', true);
      return new Promise((resolve, reject) => {
        user
          .getAccessUser(id)
          .then(response => {
            commit('SET_USER_ACCESS', response.data);

            resolve(response);
          })
          .catch(error => {

            reject(error);

          });
      });
    },
    resetUserAccess({commit},id) {
      return new Promise((resolve, reject) => {
        user
          .resetAccessUser(id)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    profile({commit}) {
      return new Promise((resolve, reject) => {
        user.profile()
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    exportUsers({commit}, payload) { 
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        user.exportUsers(payload)
            .then(response => {
              fileDownload(response, 'Users.xlsx');
              commit('SET_USERS_LOAD', false);
              resolve();
            })
            .catch(error => {
              reject(error);
            });
      });
    },
    getMSLoginUrl({ commit, dispatch }, userInfo) {
      commit('app/UPDATE_LOAD', true, { root: true });
      return new Promise((resolve, reject) => {
        getMSLoginUrl()
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            commit('app/UPDATE_LOAD', false, { root: true });
            reject(error);
          });
      });
    },
    msLoginCallback({ commit, dispatch }, payload) {
      commit('app/UPDATE_LOAD', true, { root: true });
      return new Promise((resolve, reject) => {
        msLoginCallback(payload)
          .then(response => {
            setLogged('1');
            setToken(response.data.token);
            delete response.data.instructor_data
            Auth(response.data);
            resolve();
          })
          .catch(error => {
            commit('app/UPDATE_LOAD', false, { root: true });
            reject(error);
          });
      });
    },
  },
};

export default userModule;
