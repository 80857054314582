import Global from '../../api/system/global';
import fileDownload from "js-file-download";
import router from "@/router";

const global = new Global();

const globalModule = {
  namespaced: true,
  state: {
    load: false,
    bread_val:null,
    uploadProgress: false,
    rerender: true
  },

  getters: {
    load: (state) => state.load,
    bread_val(state) {
        return state.bread_val
    },
    uploadProgress: (state) => state.uploadProgress,
    rerender: (state) => state.rerender,
  },
  mutations: {
    UPDATE_LOAD(state, val) {
      state.load = val
    },
    UPDATE_BREAD_VAL(state, val) {
      state.bread_val = val
    },
    SET_UPLOAD_PROGRESS(state, uploadProgress) {
      state.uploadProgress = uploadProgress;
    },
    UPDATE_RERENDER(state, val) {
      state.rerender = val
    },
  },

};

export default globalModule;
