import Resource from '@/api/resource';
import request from '@/utils/request';

class Teachers extends Resource {
    constructor() {
        super('teachers');
    }
    students(payload) {
        return request({
            url: `/teacher/students`,
            method: 'post',
            data: payload,
        })
    }
    studentsProgress() {
        return request({
            url: `/teacher/studentsProgress`,
            method: 'post',
        })
    }
    exportStudents(){
        return request({
            url: `/teacher/export_students`,
            method: 'post',
            responseType: "blob",
        });
    }

}

export {Teachers as default};
