import Resource from '@/api/resource';
import request from '@/utils/request';

class Courses extends Resource {
    constructor() {
        super('courses');
    }

    list(query) {
        return request({
            url: `/${this.uri}/list`,
            method: 'post',
            data: query,
        })
    }

    get(id) {
        return request({
            url: `/${this.uri}/show`,
            method: 'post',
            data: {id: id},
        })
    }

    put(id , query) {
        return request({
            url:  id ? `/${this.uri}/update` : `/${this.uri}`,
            method: 'post',
            data: query,
        })
    }

    lookups(){
        return request({
            url: '/lookups/courses_lookups',
            method: 'get'
        })
    }
}

export {Courses as default};
