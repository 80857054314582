// import store from '@/store/index.js'
export default [
  {
    path: '/schools',
    name: 'schools',
    component: () => import('@/views/modules/schools/Schools.vue'),
    meta: {
        parent: 'schools',
        breadcrumb: [
          {
            to: '/schools',
            text: 'List Schools',
          },
          
        ],
      },
  },
  {
    path: '/schools/add-school',
    name: 'add_school',
    component: () => import('@/views/modules/schools/EditSchools.vue'),
    meta: {
      parent: 'schools',
      breadcrumb: [
        {
            to: '/schools',
          text: 'Schools',
        },
        {
          text: 'Add School',
          active: true,
        },
      ],
    },
  },
  {
    path: '/schools/edit-school/:id',
    name: 'edit_school',
    component: () => import('@/views/modules/schools/EditSchools.vue'),
    meta: {
      // pageTitle: 'Edit School',
      breadcrumb: [
        {
          to: '/schools',
          text: 'Schools',
        },
        {
          text: 'edit_bread',
          active: true,
        },
      ],
    },
  },
]
