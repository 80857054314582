import Admin from "@/api/admins/admin";
import fileDownload from "js-file-download";
import router from "@/router";

const admin = new Admin();

const adminModule = {

  namespaced: true,
  state: {
    load: false,
  },
  getters: {
    load(state) {
      return state.load;
    },
  },
  mutations: {
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load;
    },
  },
  actions: {
    sendInvitation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        admin
          .sendInvitation(payload)
          .then(response => {
            resolve(response);
            commit('SET_ITEMS_LOAD', false);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },
    bulkInvitation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        admin
          .bulkInvitation(payload)
          .then(response => {
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },
    exportBulkTemplate({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        admin
          .exportBulkTemplate(payload)
          .then(response => {
            fileDownload(response, 'Bulk_template.xlsx');
            resolve(response);
            commit('SET_ITEMS_LOAD', false);
          })
          .catch(error => {
            commit('SET_ITEMS_LOAD', false);
            reject(error);
          });
      });
    },
  }
}

export default adminModule;
