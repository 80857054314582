import Resource from '@/api/resource';
import request from '@/utils/request';

class Students extends Resource {
    constructor() {
        super('student');
    }

    listModules(query) {
        return request({
            url: `/${this.uri}/modules`,
            method: 'post',
            data: query,
        })
    }

    listTopics(query) {
        return request({
            url: `/${this.uri}/topics`,
            method: 'post',
            data: query,
        })
    }

    feedback(query) {
        return request({
            url: `/${this.uri}/feedback`,
            method: 'post',
            data: query,
        })
    }
    get(id) {
        return request({
            url: `/${this.uri}/show`,
            method: 'post',
            data: {id: id},
        })
    }

    put(id , query) {
        return request({
            url:  id ? `/${this.uri}/update` : `/${this.uri}`,
            method: 'post',
            data: query,
        })
    }
    

    lookups(){
        return request({
            url: '/lookups/students_lookups',
            method: 'get'
        })
    }

    storeFeedback(query) {
        return request({
            url:  `feedback/store`,
            method: 'post',
            data: query,
        })
    }
}

export {Students as default};
