import Resource from '../../api/resource';
import request from '../../utils/request';

class Admin extends Resource {
    constructor() {
        super('admins')
    }
    sendInvitation(payload){
        return request({
            url: `/admin/send_invitation`,
            method: 'post',
            data: payload,
        })
    }
    bulkInvitation(payload){
        return request({
            url: `/admin/bulk_invitation`,
            method: 'post',
            data: payload,
        })
    }
    exportBulkTemplate(payload){
        return request({
            url: `/admin/export_bulk_template`,
            method: 'post',
            responseType: "blob",
        })
    }
}

export {Admin as default};
