export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/modules/users/Users'),
    meta: {
    //   pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'List Users',
          active: true,
        },
      ],
    }, 
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/modules/users/Roles'),
    meta: {
    //   pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'List Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/AssignRole/:id',
    name: 'assign_role',
    component: () => import('@/views/modules/users/AssignRole'),
    meta: {
    //   pageTitle: 'AssignRole',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users'
        },
        {
          text: 'Assign Role',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/permissions/:id',
    name: 'permissions',
    component: () => import('@/views/modules/users/Permissions'),
    meta: {
    //   pageTitle: 'Permissions',
      breadcrumb: [
        {
          text: 'Roles',
          to: '/roles'
        },
        {
          text: 'permissions_bread',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/add-role',
    name: 'add_role',
    component: () => import('@/views/modules/users/AddRole'),
    meta: {
    //   pageTitle: 'Add Role',
      breadcrumb: [
        {
          text: 'Roles',
          to: 'roles'
        },
        {
          text: 'Add Role',
          active: true,
        },
      ],
    },
  },
]
