
import Teachers from '@/api/teachers/teachers';
import fileDownload from "js-file-download";
import router from "@/router";

const teachers = new Teachers();

const teachersModule = {
    namespaced: true,
    state: {
        items: [],
        load: false,
        loadProg:false,
        progress:null,
    },
    getters: {
        items(state) {
            return state.items;
        },
        load(state) {
            return state.load;
        },
        loadProg(state){
            return state.loadProg;
        },
        progress(state){
            return state.progress;
        }
    },
    mutations: {
        SET_STUDENTS: (state, students) => {
            state.items = students;
        },
        SET_PROGRESS: (state, progress) => {
            state.progress = progress;
        },
        SET_STUDENTS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_LOAD_PROG:(state,loadProg)=>{
            state.loadProg = loadProg
        }
    },
    actions: {
        students({ commit },payload) {
            return new Promise((resolve, reject) => {
                commit('SET_STUDENTS_LOAD', true);
                teachers.students(payload.query).then(response => {
                    commit('SET_STUDENTS', response.students);
                    commit('SET_STUDENTS_LOAD', false);
                    resolve(response);
                }).catch((error)=>{
                    commit('SET_STUDENTS_LOAD', false);
                    reject(error)
                });
            });
        },
        studentsProgress({ commit }) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD_PROG', true);
                teachers.studentsProgress().then(response => {
                    commit('SET_PROGRESS', response);
                    commit('SET_LOAD_PROG', false);
                    resolve(response);
                }).catch((error)=>{
                    
                    reject(error)
                    commit('SET_LOAD_PROG', false);
                    console.log('test---------------------------');
                });
                
            });
        },
        exportStudents({commit}) { 
            return new Promise((resolve, reject) => {
              commit('SET_ITEMS_LOAD', true);
              teachers.exportStudents()
                  .then(response => {
                    fileDownload(response, 'Students.xlsx');
                    commit('SET_ITEMS_LOAD', false);
                    resolve();
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
          },
    }
}
export default teachersModule;