export default [
  {
    path: '/students',
    name: 'students',
    component: () => import('@/views/modules/students/Students'),
    meta: {
    //   pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'students',
          active: true,
        },
      ],
    }, 
  },
  {
    path: '/student-analytics/:id?',
    name: 'student_analytics',
    component: () => import('@/views/modules/students/StudentAnalytics.vue'),
  },
  {
    path: '/student-feedback/:id?',
    name: 'student_feedback',
    component: () => import('@/views/modules/students/StudentFeedback.vue'),
  },
]
