import Schools from '@/api/schools/schools.js';
import fileDownload from "js-file-download";
import router from "@/router";

const schools = new Schools();

const schoolsModule = {
  namespaced: true,
  state: {
    auth: null,
    item: null,
    schools: null,
    items: [],
    lookups: [],
    total: 0,
    load: false,
    schoolsAccess:[],
    photoData:null,
    avatar: null,
  },

  getters: {
    items(state) {
      return state.items;
    },
    item(state) {
      return state.item;
    },
    load(state) {
      return state.load;
    },
    total(state) {
      return state.total;
    },
    status(state) {
      return state.item.removed;
    },
    lookups(state) {
      return state.lookups;
    },
    schoolsAccess(state){
      return state.schoolsAccess;
    },
    schools(state){
      return state.schools;
    },
  },
  mutations: {
    SET_SCHOOLS: (state, schools) => {
      state.items = schools;
    },
    SET_TOTAL_SCHOOLS: (state, total) => {
      state.total = total;
    },
    SET_SCHOOLS_LOAD: (state, load) => {
      state.load = load;
    },
    SET_SCHOOL: (state, school) => {
      state.item = school;
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
    SET_SCHOOLS_ACCESS(state,schoolsAccess){
      state.schoolsAccess=schoolsAccess;
    },
  },
  actions: {
    updateBreadVale({ commit }, val) {
        commit('global/UPDATE_BREAD_VAL', val, { root: true })
    },
    schools({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_SCHOOLS_LOAD', true);
        schools.list(payload.query).then(response => {
          commit('SET_SCHOOLS', response.schools);
          commit('SET_SCHOOLS_LOAD', false);
          resolve(response);
        });
      });
    },
    editSchool({ commit }, payload) {
      return new Promise((resolve, reject) => {
        schools
          .editSchool(payload.id, payload.query)
          .then(response => {
            resolve(response)
            
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    inactiveSchool({ commit }, id) { 
      return new Promise((resolve, reject) => {
        schools
          .inactiveSchool(id)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    createSchool({ commit }, payload) {
      return new Promise((resolve, reject) => {
        schools
          .createSchool(payload.query)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    get({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        schools.get(id)
          .then(response => {
            commit('SET_SCHOOL', response.data.school)
            resolve(response.data)
            dispatch('schools/updateBreadVale', response.data.school.name, { root: true })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    remove({ commit }, id) {
      return new Promise((resolve, reject) => {
        schools
          .remove(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};

export default schoolsModule;
