export default [
  {
    path: '/modules',
    name: 'modules',
    component: () => import('@/views/modules/modules/Modules.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Modules',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-module',
    name: 'add_module',
    component: () => import('@/views/modules/modules/EditModule.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Modules',
          to: '/modules'
        },
        {
          text: 'Add Module',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-module/:id',
    name: 'edit_module',
    component: () => import('@/views/modules/modules/EditModule.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Modules',
          to: '/modules'
        },
        {
          text: 'edit_bread',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/view-module/:id',
  //   name: 'view-module',
  //   component: () => import('@/views/modules/modules/ViewModule.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Modules',
  //         to: '/Modules'
  //       },
  //       {
  //         text: 'View Modules',
  //         active: true,
  //       },
  //       {
  //         text: 'add_bread',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  {
    path: '/module/introduction/:id',
    name: 'module_introduction',
    component: () => import('@/views/modules/learning/lessons/Introduction.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'learning',
          to: '/learning'
        },
        {
          text: 'add_bread',
          active: true,
        },
      ],
    },
  },
  {
    path: '/module/lesson/:id',
    name: 'lesson',
    component: () => import('@/views/modules/learning/lessons/Lesson.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'learning',
          to: '/learning'
        },
        // {
        //   text: 'add_bread',
        //   to: '/module/introduction/nu',
        //   active: false,
        // },
        {
          text: 'add_bread',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/module/finish-lesson/:id',
    name: 'finish_lesson',
    component: () => import('@/views/modules/learning/lessons/Finish.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'learning',
          to: '/learning'
        },
        {
          text: 'add_bread',
          active: true,
        },
        // {
        //   text: 'Finish Lesson',
        //   active: true,
        // },
      ],
    },
  },
]
