export default [
  {
    path: '/courses',
    name: 'courses',
    component: () => import('@/views/modules/courses/Courses.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Courses',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-course',
    name: 'add_course',
    component: () => import('@/views/modules/courses/EditCourse.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Courses',
          to: '/courses'
        },
        {
          text: 'Add Course',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-course/:id',
    name: 'edit_course',
    component: () => import('@/views/modules/courses/EditCourse.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Courses',
          to: '/courses'
        },
        {
          text: 'edit_bread',
          active: true,
        },
      ],
    },
  },
  
]
