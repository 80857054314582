import request from '@/utils/request';

export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data: data,
    });
}

export function getMSLoginUrl() {
    return request({
        url: '/get-ms-login-url',
        method: 'get',
    });
}

export function msLoginCallback(data) {
    return request({
        url: '/ms-login/callback',
        method: 'post',
        data: data,
    });
}

export function logout(token) {
    return request({
        url: '/logout',
        method: 'post',
    });
}

export function Register(data) {
    return request({
        url: '/register',
        method: 'post',
        data: data
    });
}

export function forgotPassword(data) {
    return request({
        url: '/users/forgot_password',
        method: 'post',
        data: data
    });
}

export function resetPassword(data) {
    return request({
        url: '/users/reset_password',
        method: 'post',
        data: data
    });
}   

export function emailVerificationCode(data) {
    return request({
        url: '/send-verification-code',
        method: 'post',
        data: data
    });
}

export function verifyCode(data) {
    return request({
        url: '/verify-code',
        method: 'post',
        data: data
    });
}