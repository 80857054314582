export default [
  {
    path: '/learning/overview',
    name: 'learning',
    component: () => import('@/views/modules/learning/Overview.vue'),
  },
  {
    path: '/learning/create-lesson-plan',
    name: 'createLessonPlan',
    component: () => import('@/views/modules/learning/CreateLessonPlan.vue'),
    meta: {
      // pageTitle: 'Add School',
      breadcrumb: [
        {
          text: 'Learning',
          to: '/learning/overview'
        },
        {
          text: 'Create Lesson Plan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/learning/lesson-plans',
    name: 'lesson_plans',
    component: () => import('@/views/modules/learning/LessonPlans.vue'),
    meta: {
      // pageTitle: 'Add School',
      breadcrumb: [
        {
          text: 'Learning',
          to: '/learning/overview'
        },
        {
          text: 'Lesson Plans',
          active: true,
        },
      ],
    },
  },
  {
    path: '/learning/preview-module/:id',
    name: 'preview_module',
    component: () => import('@/views/modules/learning/PreviewModule.vue'),
    meta: {
      // pageTitle: 'Add School',
      breadcrumb: [
        {
          text: 'Learning',
          to: '/learning/overview'
        },
        {
          text: 'preview_bread',
          active: true,
        },
      ],
    },
  },
  {
    path: '/learning/learning-lesson/:id',
    name: 'learning_lesson',
    component: () => import('@/views/modules/learning/learningLesson.vue'),
    meta: {
      // pageTitle: 'Add School',
      breadcrumb: [
        {
          text: 'Learning',
          to: '/learning/overview'
        },
        {
          text: 'preview_bread',
          to: '/learning/preview-module/nu',
          active:false
        },
        {
          text: 'Assign learning',
          active: true,
        },
      ],
    },
  },
  {
    path: '/learning',
    name: 'student_learning',
    component: () => import('@/views/modules/learning/RecommendedLearning.vue'),
  },
  
]
