import Resource from '@/api/resource';
import request from '@/utils/request';

class Schools extends Resource {
    constructor() {
        super('schools');
    }
    createSchool(payload) {
        return request({
            url: `/school/store`,
            method: 'post',
            data: payload,
        })
    }

    editSchool(id, query) {
        return request({
            url: `/school/update_school/${id}`,
            method: 'post',
            data: query,
        })
    }

    list(payload) {
        return request({
            url: `/school/list_schools`,
            method: 'post',
            data: payload,
        })
    }

    get(id) {
        return request({
            url: `/school/show_school/${id}`,
            method: 'get',
        })
    }

    inactiveSchool(id) {
        return request({
            url: `/school/inactive_school/${id}`,
            method: 'post',
        })
    }
}

export {Schools as default};
