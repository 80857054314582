import Modules from '@/api/modules/modules.js';
import fileDownload from "js-file-download";
import router from "@/router";

const modules = new Modules();

const modulesModule = {
  namespaced: true,
  state: {
    items: [],
    item: null,
    lookups: [],
    total: 0,
    load: false,
  },
  getters: {
    items(state) {
      return state.items;
    },
    item(state) {
      return state.item;
    },
    load(state) {
      return state.load;
    },
    total(state) {
      return state.total;
    },
    lookups(state) {
      return state.lookups;
    },
  },
  mutations: {
    SET_ITEMS: (state, modules) => {
      state.items = modules;
    },
    SET_TOTAL_MODULES: (state, total) => {
      state.total = total;
    },
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load;
    },
    SET_ITEM: (state, school) => {
      state.item = school;
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
  },
  actions: {
    list({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        modules.list(payload).then(response => {          
          commit('SET_ITEMS', response);
          commit('SET_ITEMS_LOAD', false);
          resolve();
        });
      });
    },
    put({ commit }, payload) {
      return new Promise((resolve, reject) => {
        modules
          .put(payload.id,payload.query)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            
            reject(error);
          });
      });
    },
    lookups({ commit }) {
      return new Promise((resolve, reject) => {
        modules.lookups().then(response => {
          commit('SET_LOOKUPS', response.modules);
          resolve();
        });
      });
    },
    get({ commit , dispatch }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        modules.get(id)
          .then(response => {
            commit('SET_ITEM', response[0]);
            dispatch('schools/updateBreadVale', response[0].name, { root: true })
            commit('SET_ITEMS_LOAD', false);
            resolve(response[0]);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    reset({ commit , dispatch }, id) {
      return new Promise((resolve, reject) => {
        modules.reset(id)
          .then(response => {
            resolve(response[0]);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    remove({ commit }, id) {
      return new Promise((resolve, reject) => {
        modules
          .remove(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    restore({ commit }, id) {
      return new Promise((resolve, reject) => {
        modules
          .restore(id)
          .then(response => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    hardRemove({ commit }, id) {
      return new Promise((resolve, reject) => {
        modules
          .hardRemove(id)
          .then(response => {
            
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default modulesModule;
