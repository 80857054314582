import Resource from '@/api/resource';
import data from '@/router/routes/ui-elements';
import request from '@/utils/request';

class LessonPlans extends Resource {
    constructor() {
        super('lessonPlans');
    }
    createSchool(payload) {
        return request({
            url: `/school/store`,
            method: 'post',
            data: payload,
        })
    }

    editSchool(id, query) {
        return request({
            url: `/school/update_school/${id}`,
            method: 'post',
            data: query,
        })
    }

    list() {
        return request({
            url: `/school/list_schools`,
            method: 'get',
        })
    }

    get(id) {
        return request({
            url: `/school/show_school/${id}`,
            method: 'get',
        })
    }

    users_lookup(id) {
        return request({
            url: `/lookups/users_lookups/${id}`,
            method: 'get',
        })
    }
    inactiveSchool(id) {
        return request({
            url: `/school/inactive_school/${id}`,
            method: 'post',
        })
    }
    assignUsers(id,payload) {
        return request({
            url: `/planLesson/assignUsers/${id}`,
            method: 'post',
            data: payload,
        })
    }
    enrolUser(id) {
        return request({
            url: `/planLesson/enrolUser/${id}`,
            method: 'post',
        })
    }
    answerQue(payload) {
        return request({
            url: `/answers/store`,
            method: 'post',
            data: payload,
        })
    }

    finishTopic(payload) {
        return request({
            url: `/answers/finishTopic`,
            method: 'post',
            data: payload,
        })
    }

    answerOpenQuestions(payload) {
        return request({
            url: `/answers/OQAnswer`,
            method: 'post',
            data: payload,
        })
    }
    AttachPDFAnswer(payload) {
        return request({
            url: `/answers/AttachPDFAnswer`,
            headers: { "Content-Type": "multipart/form-data" },
            method: 'post',
            data: payload,
        })
    }
}

export {LessonPlans as default};
